
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  name: "customer-financial-statements",
  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Financial Scoring", ["Customer"]);
    });

    return {};
  },
  data() {
    let isLoading = true;
    const route = useRoute();
    const cstmr_id = route.params.id ?? null;
    const newFinancialScoringUrl =
      "#/customers/details/" +
      cstmr_id +
      "/financial-scoring-hh/financial-scoring";

    return {
      newFinancialScoringUrl,
    };
  },
  // methods: {
  //   init() {},
  // },
  // async mounted() {
  //   this.init();
  // },
});
